import React, { Suspense, useEffect } from 'react';
import { ClerkProvider } from '@clerk/clerk-react'
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './context/ThemeContext';
import { Layout } from './components';
import { Home, About, Contact, Profile, ImageProcessing, Login, Signup }  from './pages';
import './i18n/';
const TRACKING_ID = process.env.GA_KEY || 'G-KGGQQ7NNZ8';

ReactGA.initialize(TRACKING_ID);

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY || '';


const usePageTracking = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
}

const App: React.FC = () => {
  usePageTracking();

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <ThemeProvider>
        <Router>
          <Layout>
            <Suspense fallback={<div>Loading...</div>}>
              <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/profile" element={<Profile />} />
                  <Route path="/image-processor" element={<ImageProcessing />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/login/factor-one" element={<Login />} />
                  <Route path="/signup" element={<Signup />} />
              </Routes>
            </Suspense>
          </Layout>
        </Router>
      </ThemeProvider>


    </ClerkProvider>
  );
}

export default App;
