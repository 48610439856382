export const translations = {
  'en': {
    register: 'Sign Up',
    register_description: 'Create your account to access the system.',
  },
  'pt-br': {
    register: 'Cadastro',
    register_description: 'Crie sua conta para acessar o sistema.',
  },
  'pt': {
    register: 'Registar',
    register_description: 'Crie a sua conta para aceder ao sistema.',
  },
  'fr': {
    register: 'Inscription',
    register_description: 'Créez votre compte pour accéder au système.',
  }
};
