import React, { ReactNode } from 'react';
import { useTheme } from '../../context/ThemeContext';
import { Link, useLocation } from 'react-router-dom';

interface MenuItemProps {
  to: string;
  closeMenu: () => void;
  children: ReactNode,
  onClick?: () => void;
}

export const MenuItem: React.FC<MenuItemProps> = ({ to, closeMenu, children, onClick }) => {
  const { theme } = useTheme();
  const location = useLocation();
  const isActive = location.pathname === to;

  const textColor = theme === 'light' ? 'text-gray-800' : 'text-white';
  const hoverTextColor = theme === 'light' ? 'hover:text-blue-500' : 'hover:text-blue-300';
  const backgroundColor = theme === 'light' ? 'sm:bg-gray-400' : 'sm:bg-gray-800';
  const activeBackgroundColor = theme === 'light' ? 'sm:bg-blue-100' : 'sm:bg-gray-700';
  const hoverBackgroundColor = theme === 'light' ? 'hover:bg-gray-100' : 'hover:bg-gray-700';


  const linkClasses = `md:px-4 md:py-2 md:rounded-md md:transition-colors md:duration-300 ${
    isActive ? 
      theme === 'light' ? 'font-semibold text-blue-700 border-4 border-blue-500' : 'hover:font-medium text-blue-200 border-4 border-blue-200'
      : 'hover:font-medium'
  } ${theme === 'light' ? 'text-gray-800 hover:bg-gray-100' : 'text-white hover:bg-gray-700'}`;

  return (
    <Link
      to={to}
      className={`block px-4 py-2 ${textColor} ${backgroundColor} 
                  ${isActive ? activeBackgroundColor : hoverBackgroundColor} 
                  ${hoverTextColor} transition duration-300 ease-in-out
                  ${linkClasses}`}
      onClick={onClick || closeMenu}
    >
      <div className="flex flex-row items-center">
        {isActive && (
          <svg className="md:hidden h-5 w-5 ml-2 fill-current" viewBox="0 0 20 20">
            <path fillRule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H5a1 1 0 110-2h9.586l-2.293-2.293a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        )}
        {children}
      </div>
    </Link>
  );
};
