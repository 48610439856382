import React from 'react';
import { SignUp as SignUpClerk } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';

export const Signup: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const bgTheme = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';

  return (
    <div className={`min-h-screen ${bgTheme}`}>
      <section className="text-center py-20">
        <h1 className="text-4xl font-bold">{t('header:company_name')} - {t('signup:register')}</h1>
        <p className="text-xl text-gray-600 mt-4">{t('signup:register_description')}</p>
      </section>
      <div className="flex justify-center items-center">
        <SignUpClerk />
      </div>
    </div>
  );
};
