import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';

export const Contact: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const bgTheme = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';
  const bgThemeSecondary = theme === 'dark' ? 'bg-background-dark text-text-dark' : 'bg-background-light text-text-light';

  return (
    <div className="space-y-10">
      <section className={`text-center py-10 ${bgTheme}`}>
        <h1 className="text-4xl font-bold">{t('contact:contact')}</h1>
        <p className="text-lg mt-2 mx-auto max-w-4xl">
          {t('contact:contact_description')}
        </p>
      </section>
      <div className={`py-10 text-center ${bgThemeSecondary}`}>
        <div className="max-w-4xl mx-auto px-4">
          <p>Email: {t('contact:email')}</p>
          <p>Phone: {t('contact:phone')}</p>
        </div>
      </div>
    </div>
  );
}
