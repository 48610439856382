import { useState } from 'react';
import axios from 'axios';

interface ImageProcessResponse {
  description: string;
}

const useImageProcessor = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [description, setDescription] = useState<string | null>(null);

  const uploadAndProcessImage = async (file: File) => {
    setIsLoading(true);
    setError(null);
    setDescription(null);

    try {
      const formData = new FormData();
      formData.append('image', file);

      // Upload image
      const uploadResponse = await axios.post('http://localhost:3000/upload', formData);
      const { fileName } = uploadResponse.data;

      // Process image
      const processResponse = await axios.post<ImageProcessResponse>('http://localhost:3000/process', { fileName });
      setDescription(processResponse.data.description);
    } catch (err: any) {
      setError(err.response?.data || 'Erro desconhecido durante o upload ou processamento da imagem.');
    } finally {
      setIsLoading(false);
    }
  };

  return { uploadAndProcessImage, isLoading, error, description };
};

export default useImageProcessor;
