import { useUser, useClerk } from '@clerk/clerk-react';

export const useCustomer = () => {
  const { user, isLoaded, isSignedIn } = useUser();
  const { signOut } = useClerk();
  

  return {
    user: {
      ...user,
      email: user?.primaryEmailAddress?.emailAddress,
    },
    isAuthenticated: isSignedIn,
    isLoading: !isLoaded,
    signOut,
  };
};

