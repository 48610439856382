import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useTheme } from '../../context/ThemeContext';

interface UserProfile {
  name?: string;
  picture?: string;
  email?: string;
}

export const Profile: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0<UserProfile>();
  const { theme } = useTheme();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated && user ? (
    <div className={`${theme === 'dark' ? 'text-white bg-gray-800' : 'text-gray-800 bg-gray-100'}`}>
      <img src={user.picture} alt={user.name} />
      <h2>{user.name}</h2>
      <p>Email: {user.email}</p>
    </div>
  ) : (
    <div>No user is authenticated</div>
  );
}
