export const translations = {
  'en': {
    title: 'aaa'
  },
  'pt-br': {
    title: 'aaa'
  },
  'pt': {
    title: 'aaa'
  },
  'fr': {
    title: 'aaa'
  }
};

