export const translations = {
  'en': {
    company_name: '2Vision'
  },
  'pt-br': {
    company_name: '2Vision'
  },
  'pt': {
    company_name: '2Vision'
  },
  'fr': {
    company_name: '2Vision'
  }
};
