import React from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';

export const Footer: React.FC = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <footer className={`py-4 text-center ${theme === 'light' ? 'bg-background-light text-text-light' : 'bg-background-dark text-text-dark'}`}>
      <p>{t('footer:copyright')}</p>
    </footer>
  );
}
