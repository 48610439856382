import React from 'react';
import { useTranslation } from 'react-i18next';
import { FlagIcon } from 'react-flag-kit';
import { useTheme } from '../../context/ThemeContext';
import { useCustomer } from '../../hooks';
import { MenuItem } from './MenuItem';

interface MenuProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (isOpen: boolean) => void;
}

export const Menu: React.FC<MenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const { isAuthenticated, isLoading, signOut } = useCustomer();
  const { theme, setTheme } = useTheme();
  const { t, i18n } = useTranslation();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  
  const toggleTheme = () => setTheme(theme === 'light' ? 'dark' : 'light');
  const menuBgColor = theme === 'light' ? 'bg-gray-400' : 'bg-gray-900';
  const themeButtonClasses = `py-2 px-4 rounded-lg text-white font-bold ${
    theme === 'light' ? 'bg-gray-600 hover:bg-gray-900' : 'text-gray-900 bg-gray-500 hover:bg-gray-200'
  } transition-colors duration-300`;

  const closeMenu = () => setIsMenuOpen(false);
  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error('Logout failed:', error);
    }
    closeMenu();
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    closeMenu();
  };

  return (
    <nav className={`fixed inset-y-0 right-0 z-50 ${menuBgColor} transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 ease-in-out md:relative md:transform-none md:flex md:items-center md:bg-transparent md:p-0 md:space-x-4`}>
      <button className="md:hidden absolute top-0 right-0 p-2" onClick={()=>setIsMenuOpen(false)}>
        <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
        </svg>
      </button>
      <MenuItem to="/" closeMenu={closeMenu}>{t('menu:home')}</MenuItem>
      <MenuItem to="/about" closeMenu={closeMenu}>{t('menu:about')}</MenuItem>
      <MenuItem to="/contact" closeMenu={closeMenu}>{t('menu:contact')}</MenuItem>
      { isAuthenticated && <MenuItem to="/profile" closeMenu={closeMenu}>{t('menu:profile')}</MenuItem> }
      { isAuthenticated && <MenuItem to="/image-processor" closeMenu={closeMenu}>{t('menu:imageProcessor')}</MenuItem> }
      { isAuthenticated
        ? <MenuItem to="#" onClick={handleLogout} closeMenu={closeMenu}>{t('menu:logout')}</MenuItem>
        : <MenuItem to="/login" closeMenu={closeMenu}>{t('menu:login')}</MenuItem>
      }
      { !isAuthenticated
        && <MenuItem to="/signup" closeMenu={closeMenu}>{t('menu:signup')}</MenuItem>
      }
      <button onClick={toggleTheme} className={themeButtonClasses}>
        {theme === 'light' ? 'Dark' : 'Light'} Mode
      </button>
      <div className="flex space-x-2 p-2">
        <FlagIcon code="GB" size={24} onClick={() => changeLanguage('en')} />
        <FlagIcon code="BR" size={24} onClick={() => changeLanguage('pt-br')} />
        <FlagIcon code="PT" size={24} onClick={() => changeLanguage('pt')} />
        <FlagIcon code="FR" size={24} onClick={() => changeLanguage('fr')} />
      </div>
    </nav>
  );
};
