export const translations = {
  'en': {
    services: {
      header: "Our Audiovisual Services",
      intro: "Explore the comprehensive range of audiovisual services we offer to enhance your digital presence and engage your audience.",
      videoProduction: {
        title: "Video Production",
        description: "From corporate videos to engaging promotional content, our video production services are tailored to convey your message effectively and creatively."
      },
      liveEventCoverage: {
        title: "Live Event Coverage",
        description: "Capture every moment with our live event coverage services, perfect for conferences, concerts, and special events."
      },
      dronePhotography: {
        title: "Drone Aerial Photography",
        description: "Elevate your projects with our drone aerial photography and videography services, offering unique perspectives and stunning visuals."
      }
    }
  },
  'pt-br': {
    services: {
      header: "Nossos Serviços Audiovisuais",
      intro: "Explore a ampla gama de serviços audiovisuais que oferecemos para potencializar sua presença digital e engajar seu público.",
      videoProduction: {
        title: "Produção de Vídeo",
        description: "De vídeos corporativos a conteúdos promocionais cativantes, nossos serviços de produção de vídeo são personalizados para transmitir sua mensagem de forma eficaz e criativa."
      },
      liveEventCoverage: {
        title: "Cobertura de Eventos Ao Vivo",
        description: "Capture cada momento com nossos serviços de cobertura de eventos ao vivo, perfeitos para conferências, concertos e eventos especiais."
      },
      dronePhotography: {
        title: "Fotografia Aérea com Drones",
        description: "Eleve seus projetos com nossos serviços de fotografia e videografia aérea com drones, oferecendo perspectivas únicas e visuais impressionantes."
      }
    }
  },
  'pt': {
    services: {
      header: "Os Nossos Serviços Audiovisuais",
      intro: "Explore a gama abrangente de serviços audiovisuais que oferecemos para realçar a sua presença digital e captar a atenção do seu público.",
      videoProduction: {
        title: "Produção de Vídeo",
        description: "Desde vídeos corporativos até conteúdos promocionais envolventes, os nossos serviços de produção de vídeo são concebidos para transmitir a sua mensagem de forma eficaz e criativa."
      },
      liveEventCoverage: {
        title: "Cobertura de Eventos ao Vivo",
        description: "Capture cada momento com os nossos serviços de cobertura de eventos ao vivo, ideais para conferências, concertos e ocasiões especiais."
      },
      dronePhotography: {
        title: "Fotografia Aérea com Drone",
        description: "Eleve os seus projetos com os nossos serviços de fotografia e videografia aérea com drone, oferecendo perspectivas únicas e imagens deslumbrantes."
      }
    }
  },
  'fr': {
    services: {
      header: "Nos Services Audiovisuels",
      intro: "Explorez notre gamme complète de services audiovisuels conçus pour renforcer votre présence numérique et engager votre audience.",
      videoProduction: {
        title: "Production Vidéo",
        description: "Des vidéos d'entreprise aux contenus promotionnels captivants, nos services de production vidéo sont adaptés pour transmettre efficacement et créativement votre message."
      },
      liveEventCoverage: {
        title: "Couverture d'Événements en Direct",
        description: "Capturez chaque instant avec nos services de couverture d'événements en direct, parfaits pour les conférences, concerts et événements spéciaux."
      },
      dronePhotography: {
        title: "Photographie Aérienne par Drone",
        description: "Élevez vos projets avec nos services de photographie et vidéographie aérienne par drone, offrant des perspectives uniques et des visuels époustouflants."
      }
    }
  }
};
