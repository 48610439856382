export const translations = {
  'en': {
    welcome: 'Welcome to',
    slogan: 'Delivering innovative solutions with excellence',
  },
  'pt-br': {
    welcome: 'Bem-vindo(a) a',
    slogan: 'Entregando soluções inovadoras com excelência',
  },
  'pt': {
    welcome: 'Bem-vindo(a) a',
    slogan: 'Entregando soluções inovadoras com excelência',
  },
  'fr': {
    welcome: 'Bienvenue chez',
    slogan: 'Fournir des solutions innovantes avec excellence',
  }
};
