export const translations = {
  'en': {
    login: 'Login',
    slogan: 'Delivering innovative solutions with excellence',
    login_description: 'Log in to access your account.',
  },
  'pt-br': {
    login: 'Login',
    slogan: 'Entregando soluções inovadoras com excelência',
    login_description: 'Faça login para acessar sua conta.',
  },
  'pt': {
    login: 'Login',
    slogan: 'Entregar soluções inovadoras com excelência',
    login_description: 'Faça login para aceder à sua conta.',
  },
  'fr': {
    login: 'Connexion',
    slogan: 'Fournir des solutions innovantes avec excellence',
    login_description: 'Connectez-vous pour accéder à votre compte.',
  }
};
