import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../context/ThemeContext';
import { Menu } from '../Menu';

export const Header: React.FC = () => {
  const { theme } = useTheme();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();

  const themeButtonBg = theme === 'light' ? 'bg-gray-500' : 'bg-gray-600';
  const themeButtonHover = theme === 'light' ? 'hover:bg-blue-700' : 'hover:bg-gray-500';

  return (
    <header className={`flex justify-between items-center p-4 md:px-8 ${theme}`}>
      <div className="text-2xl font-bold">{t('header:company_name')}</div>
      <button className={`md:hidden ${themeButtonHover} ${themeButtonBg}`} onClick={() => setIsMenuOpen(!isMenuOpen)}>
        <svg viewBox="0 0 100 80" width="40" height="40" fill="#FFF">
          <rect width="100" height="10"></rect>
          <rect y="30" width="100" height="10"></rect>
          <rect y="60" width="100" height="10"></rect>
        </svg>
      </button>
      <Menu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
    </header>
  );
};
