// src/components/ImageProcessor/ImageProcessor.tsx
import React, { ChangeEvent, useState } from 'react';
import useImageProcessor from '../../hooks/useImageProcessor';

export const ImageProcessor = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { uploadAndProcessImage, isLoading, error, description } = useImageProcessor();

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleUploadAndProcess = () => {
    if (selectedFile) {
      uploadAndProcessImage(selectedFile);
    }
  };

  return (
    <div>
      <h2>Upload and Process Image</h2>
      <input type="file" onChange={handleFileChange} disabled={isLoading} />
      <button onClick={handleUploadAndProcess} disabled={!selectedFile || isLoading}>
        Upload and Process
      </button>
      {isLoading && <p>Processing...</p>}
      {error && <p>Error: {error}</p>}
      {description && <div>
        <h3>Image Description</h3>
        <p>{description}</p>
      </div>}
    </div>
  );
};
