export const translations = {
  en: {
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    profile: 'Profile',
    imageProcessor: 'Image Processor',
    login: 'Login',
    signup: 'Sign Up',
  },
  'pt-br': {
    home: 'Início',
    about: 'Quem somos',
    contact: 'Contato',
    profile: 'Perfil',
    imageProcessor: 'Processado de Imagem',
    login: 'Login',
    signup: 'Sign Up',
  },
  pt: {
    home: 'Início',
    about: 'Sobre',
    contact: 'Contacto',
    profile: 'Perfil',
    imageProcessor: 'Perfil',
    login: 'Login',
    signup: 'Sign Up',
  },
  fr: {
    home: 'Accueil',
    about: 'À propos',
    contact: 'Contact',
    profile: 'Profil',
    imageProcessor: 'Profil',
    login: 'Login',
    signup: 'Sign Up',
  }
};
