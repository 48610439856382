// src/pages/ImageProcessing/ImageProcessing.tsx
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { ImageProcessor } from '../../components';

export const ImageProcessing: React.FC = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <div>
      <h1>Image Processing</h1>
      <ImageProcessor />
    </div>
  ) : (
    <div>Please log in to access this page.</div>
  );
};
