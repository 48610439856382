import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {
  CTAButtonTranslations,
  FooterTranslations,
  HeaderTranslations,
  MenuTranslations,
  ServicesTranslations
} from '../components';
import {
  AboutTranslations,
  Contacttranslations,
  HomeTranslations,
  ProfileTranslations,
  LoginTranslations,
  SignupTranslations
} from '../pages';

const resources = {
  en: {
    ctaButton: CTAButtonTranslations.en,
    header: HeaderTranslations.en,
    footer: FooterTranslations.en,
    menu: MenuTranslations.en,
    services: ServicesTranslations.en,
    about: AboutTranslations.en,
    contact: Contacttranslations.en,
    home: HomeTranslations.en,
    profile: ProfileTranslations.en,
    login: LoginTranslations.en,
    signup: SignupTranslations.en
  },
  'pt-br': {
    ctaButton: CTAButtonTranslations['pt-br'],
    footer: FooterTranslations['pt-br'],
    header: HeaderTranslations['pt-br'],
    menu: MenuTranslations['pt-br'],
    services: ServicesTranslations['pt-br'],
    about: AboutTranslations['pt-br'],
    contact: Contacttranslations['pt-br'],
    home: HomeTranslations['pt-br'],
    profile: ProfileTranslations['pt-br'],
    login: LoginTranslations['pt-br'],
    signup: SignupTranslations['pt-br']
  },
  pt: {
    ctaButton: CTAButtonTranslations.pt,
    footer: FooterTranslations.pt,
    header: HeaderTranslations.pt,
    menu: MenuTranslations.pt,
    services: ServicesTranslations.pt,
    about: AboutTranslations.pt,
    contact: Contacttranslations.pt,
    home: HomeTranslations.pt,
    profile: ProfileTranslations.pt,
    login: LoginTranslations.pt,
    signup: SignupTranslations.pt
  },
  fr: {
    ctaButton: CTAButtonTranslations.fr,
    footer: FooterTranslations.fr,
    menu: MenuTranslations.fr,
    services: ServicesTranslations.fr,
    about: AboutTranslations.fr,
    contact: Contacttranslations.fr,
    home: HomeTranslations.fr,
    profile: ProfileTranslations.fr,
    login: LoginTranslations.fr,
    signup: SignupTranslations.fr
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['navigator'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

